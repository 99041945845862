<template>
  <div v-if="shops">
    <CRow class="d-flex justify-content-center" style="padding:10px">
      <CCol md="12">
        <CJumbotron color="white" class="p-4 mb-0" style="border-radius:15px">
          <h4 class="text-dark text-center">
            {{ $t('systemUserManual') }}
            <span class="text-black font-weight-normal">Silom</span>
            <span class="text-success font-weight-normal">CRM</span>
          </h4>
        </CJumbotron>

        <CRow class="mt-4">
          <CCol md="4" sm="6" v-for="list in getMenu()" :key="list.name">
            <router-link :to="list.link" class="underline">
              <CJumbotron style="border-radius:15px" color="white" class="p-2 font-weight-normal">
                <CWidgetIcon
                  class="mb-0 font-weight-normal"
                  :header="list.name"
                  :text="$t('systemUserManual')"
                  color="success"
                >
                <CIcon name="cil-settings" />
                </CWidgetIcon>
              </CJumbotron>
            </router-link>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop.vue'
export default {
  components: { NoShop },
  data() {
    return {
      menu: [
    {
        name: this.$i18n.t('registerLineOA'),
        link: '/manual/lineoa',
    },
    {
        name: this.$i18n.t('registerStore'),
        link: '/manual/line-register',
    },
    {
        name: this.$i18n.t('setLoyaltyPoints'),
        link: '/manual/loyalty-point',
    },
    {
        name: this.$i18n.t('createRewardCoupons'),
        link: '/manual/create-coupon',
    },
    {
        name: this.$i18n.t('createEVoucher'),
        link: '/manual/evoucher',
    },
    {
        name: this.$i18n.t('createPreVoucher'),
        link: '/manual/pvoucher',
    },
    {
        name: this.$i18n.t('importRetrieveLoyaltyPoints'),
        link: '/manual/point',
    },
    {
        name: this.$i18n.t('setRewardPointCards'),
        link: '/manual/reward-card',
    },
    {
        name: this.$i18n.t('setAutoNotifications'),
        link: '/manual/line-notify',
    },
    {
        name: this.$i18n.t('productMenu'),
        link: '/manual/menu',
    },
],

    }
  },
  computed: {
    ...mapGetters(['shops']),
  },
  methods:{
    getMenu(){
      let manualMenu =  [
    {
        name: this.$i18n.t('registerLineOA'),
        link: '/manual/lineoa',
    },
    {
        name: this.$i18n.t('registerStore'),
        link: '/manual/line-register',
    },
    {
        name: this.$i18n.t('setLoyaltyPoints'),
        link: '/manual/loyalty-point',
    },
    {
        name: this.$i18n.t('createRewardCoupons'),
        link: '/manual/create-coupon',
    },
    {
        name: this.$i18n.t('createEVoucher'),
        link: '/manual/evoucher',
    },
    {
        name: this.$i18n.t('createPreVoucher'),
        link: '/manual/pvoucher',
    },
    {
        name: this.$i18n.t('importRetrieveLoyaltyPoints'),
        link: '/manual/point',
    },
    {
        name: this.$i18n.t('setRewardPointCards'),
        link: '/manual/reward-card',
    },
    {
        name: this.$i18n.t('setAutoNotifications'),
        link: '/manual/line-notify',
    },
    {
        name: this.$i18n.t('productMenu'),
        link: '/manual/menu',
    },
]
return manualMenu
    }
  }
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}
</style>
